export const columns = [
  {
    field: "name",
    label: "科目",
    align: "left",
    width: "",
  },
  {
    field: "action",
    label: "操作",
    align: "center",
    width: "120",
  },
];

export const searchOptions = [];
