<!--
 * @Description:
 * @Version: 0.0.1
 * @Autor: Silence
 * @Date: 2023-05-06 17:27:29
 * @LastEditors:
 * @LastEditTime: 2023-05-06 17:27:29
-->
<template>
  <div>
    <Main
      class="h-full"
      ref="main"
      :is-page="false"
      :on-params="onParams"
      :columns="columns"
      :is-selection="false"
      api="/subject/list"
    >
      <template #action>
        <el-input
          v-model="newSubject"
          style="width: 200px"
          placeholder="新科目名称"
        ></el-input
        ><el-button
          type="success"
          style="margin-left: 5px"
          @click="handleCreateSubject"
          >创建</el-button
        >
      </template>
      <template #table-item-name="{ row }">
        <el-input
          v-if="row.id === editId"
          v-model="row['name']"
          @focus="handleFocusEditRow(row['name'])"
          @blur="handleEditRow(row)"
          @keyup.enter.native="handleEditRow(row)"
        ></el-input>
        <span v-else>{{ row.name }}</span>
      </template>
      <template #table-item-action="{ row }">
        <el-button type="text" @click="handleUpdateSubject(row)"
          >修改</el-button
        >
        <el-button
          type="text"
          class="text-red-600"
          @click="handleDeleteSubject(row)"
          >删除</el-button
        >
      </template>
    </Main>
  </div>
</template>
<script>
import Main from "../components/main/index.vue";
import { columns } from "./config";
export default {
  name: "",
  components: { Main },
  props: {},
  data() {
    return {
      columns,
      newSubject: "",
      editId: "",
      editOldName: "",
      searchform: {
        pageSize: 999,
        pageIndex: 1,
      },
    };
  },
  created() {},
  mounted() {
    this.onFind();
  },
  methods: {
    onParams() {
      return this.searchform;
    },
    // 查询
    onFind() {
      this.$refs["main"].onFind();
    },

    // 创建科目
    handleCreateSubject() {
      if (!this.newSubject) {
        this.$message.error("请输入新科目名称");
        return;
      }
      this.$cloud
        .post("/subject/create", { name: this.newSubject })
        .then(() => {
          this.$message.success("创建成功！");
          this.newSubject = "";
          this.onFind();
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error(err);
        });
    },

    // 编辑学科
    handleUpdateSubject(row) {
      // 判断是否有修改失败的数据 有就恢复回去  但是这儿 改数据有点麻烦 刷新一下
      if (this.editOldName) {
        this.editOldName = "";
        this.$refs["main"].onFind();
      }
      this.editId = row.id;
    },
    handleFocusEditRow(name) {
      this.editOldName = name;
    },
    handleEditRow(row) {
      this.$cloud
        .post("/subject/edit", { id: row.id, name: row.name })
        .then(() => {
          this.onFind();
          this.editOldName = "";
          this.editId = null;
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error(err);
        });
    },

    // 删除学科
    handleDeleteSubject(row) {
      this.$confirm("确定删除该学科吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$cloud.get("/subject/delete", { id: row["id"] }).then(() => {
          this.$message.success("删除成功！");
          this.onFind();
        }).catch(err=>{
          this.$message.error(err)
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
